@font-face {
    font-family: 'Inter';
    src: local('Inter Regular'), local('Inter-Regular'),
        url('Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter ExtraBold'), local('Inter-ExtraBold'),
        url('Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Bold'), local('Inter-Bold'),
        url('Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Medium'), local('Inter-Medium'),
        url('Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Black'), local('Inter-Black'),
        url('Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: local('Inter Light'), local('Inter-Light'),
        url('Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}


