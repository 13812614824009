/* Importing Bootstrap SCSS file. */
@import "node_modules/bootstrap/dist/css/bootstrap.min";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* Importing SCSS variable file. */
@import "/src/_variables.scss";

/* Importing font stylesheet file. */
@import "/src/assets/font/stylesheet";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $font;
  font-size: 16px;
  font-weight: 200;
}

button{
  display: inline-block;
}

a:active,
.link:focus,
.link:active,
.link:visited,
textarea:focus,
textarea:active,
textarea:visited,
input:focus,
button:active,
input:active,
a:focus,
button:focus,
input:focus a:visited,
button:visited,
input:visited,
select:active,
select:focus,
select:visited,
div:active,
div:focus,
div:visited {
  outline: none !important;
  outline-style: none !important;
}

.x-primary-color {
  color: $primary !important;
}

.x-primary-bg-color {
  background-color: $primary !important;
}

.x-secondary-color {
  color: $secondary !important;
}

.x-secondary-bg-color {
  background-color: $secondary;
}

.x-accent-color {
  color: $accent !important;
}

.x-accent-bg-color {
  background-color: $accent;
}

.x-btn {
  border: unset;
  padding: 0 20px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  text-decoration: none !important;
  transition: 0.2s all ease-in-out;

  &:disabled {
    opacity: 0.5;

    &:hover {
      opacity: 0.5 !important;
    }
  }

  &.x-btn-link {
    background: unset;
    color: $primary;
    padding: 0 !important;
  }

  &.x-btn-primary {
    background-color: $primary;
    color: $black;

    &:hover {
      opacity: 0.8;
    }
  }

  &.x-btn-primary-outline {
    border: 2px solid $primary;
    color: $primary;
    background-color: unset;

    &:hover {
      background-color: $primary;
      color: #fff;
    }
  }

  &.x-btn-light-outline {
    border: 2px solid $gray;
    color: #fff;
    background-color: unset;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }

  &.x-btn-secondary-outline {
    border: 2px solid $secondary;
    color: #000;

    &:hover {
      background-color: $secondary;
    }
  }

  &.x-btn-secondary {
    background-color: $secondary;
    color: #fff;

    &:hover {
      opacity: 1;
    }
  }

  &.x-btn-accent {
    background-color: $accent;
    color: #fff;

    &:hover {
      opacity: 1;
    }
  }

  &.x-btn-danger {
    background-color: rgb(230, 82, 82);
    color: #fff;

    &:hover {
      opacity: 0.7;
    }
  }
}

a {
  text-decoration: none !important;
  display: inline-block;
}

.form {
  margin: 30px 0;

  label{
    font-size: 14px;
  }

  .form-checkbox {
    ul {
      margin: 20px 0;
      padding: 0;
      list-style-type: none;

      li {
        margin-bottom: 15px;
        display: flex;
        align-items: center;

        input {
          width: 30px;
        }
      }
    }
  }

  .form-wrapper {
    margin-top: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    border: 1px solid $linegray;
    padding: 10px 20px;
    border-radius: 8px;
    background: unset;

    &.read-only {
      background: #f5f5f5;
    }

    &:focus {
      border-color: red;
    }

    input,
    select,
    textarea {
      flex-grow: 1;
      background-color: unset;
      border: unset;
      color: #fff;
    }

    .aside {
      min-width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      object-fit: contain;
      padding: 0 18px;

      mat-icon {
        color: #c5c9cc;

        &:hover {
          color: $accent;
        }
      }
    }
  }

  select {
    color: gray;
  }

  textarea {
    height: 100px;
  }
}

.font-900 {
  font-weight: 900;
}

.font-800 {
  font-weight: 800;
}

.font-700 {
  font-weight: 700;
}

.font-600 {
  font-weight: 600;
}

.font-500 {
  font-weight: 500;
}

.font-400 {
  font-weight: 400;
}

.font-300 {
  font-weight: 300;
}

.font-200 {
  font-weight: 200;
}

.font-100 {
  font-weight: 100;
}

.cursor-pointer {
  cursor: pointer;
}

.box {
  background: #0d0f12;
  box-shadow: 0px 2px 4px rgba(13, 15, 18, 0.12);
  border-radius: 16px;
  border: 1px solid $linegray;;
  padding: 30px;

  .heading {
    color: $secondary;
    font-weight: 600;
    font-size: 1.2em;
  }

  .subheading {
    color: gray;
  }
}

.box-min {
  width: 300px;
}

.box-100 {
  width: 100%;
}

//Accordion
.accordion-item{
  background-color: unset !important;
  border: unset !important;
}

.accordion-header{
  background-color: unset !important;
  color: #fff !important;
}

.accordion-button{
  background-color: unset !important;
  outline: none !important;
  box-shadow: none!important;
  color: #fff !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgb(40, 40, 40) !important;
}

.accordion-button:not(.collapsed){
  border-radius: 10px 10px 0 0 !important;
}


.accordion-body{
  background-color: #0D0F12 !important;
  color: $white;
  border-radius: 0 0 10px 10px !important;
}

.accordion-button::after{
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='green'/></g></svg>") !important;
}

.accordion-button:not(.collapsed)::after{
  background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='green'/></g></svg>") !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: unset;
  -webkit-text-fill-color: $white;
  -webkit-box-shadow: 0 0 0px 1000px unset inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.ngx-pagination .current{
  background: unset !important;
  color: $accent !important;
  font-weight: 800 !important;
}
.mat-mdc-dialog-container .mdc-dialog__surface{
  background-color: #0D0F12 !important;
}

.checkbox{
  .round {
    position: relative;
  }

  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 28px;
    left: 0;
    position: absolute;
    top: 0;
    width: 28px;
  }

  .round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
  }

  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: #000000;
    border-color: #000000;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}

.mdc-list-item--selected .mdc-list-item__primary-text, .mdc-list-item--activated .mdc-list-item__primary-text{
  color: #fff !important;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
