$primary: #6EE755;
$secondary: #0D0F12;
$accent: #F82400;
$success: #198754;
$font: 'Inter';
$gray: #737373b8;
$textgray: rgba(255, 255, 255, 0.72);
$linegray: rgba(255, 255, 255, 0.15);
$black: #000;
$white: #fffffff2;
